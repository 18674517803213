import { useEffect } from "react";

import {
	useGetLoggedIn,
	useSetUserRoles
} from "_common/hooks/auth";
import { getUserRoles } from "_services/api/pro";

const SetUserRoles = () => {
	const setUserRoles = useSetUserRoles ();
	const isLoggedIn   = useGetLoggedIn ();

	async function fetchUserRoles () {
		try {
			const userRoles = await getUserRoles ();

			setUserRoles ( userRoles );
		} catch ( error ) {
			// console.log ( "=============  error:", error );

		}
	}

	useEffect ( () => {
		if ( isLoggedIn ) {
			// setUserRoles ( userProfile?.userRoles );
			fetchUserRoles ();

			return;
		}

		setUserRoles ( undefined );
	}, [ isLoggedIn ] );

	return null;
};

export default SetUserRoles;
