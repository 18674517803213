import XHR         from "_common/xhr";
import { configs } from "_config/index";

export const getUserRoles = async function () {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_SRV }user/roles`
	} );

	return response;
};

export const getProPlans = async function ( { countryCode, screen } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_SRV }pro/info?countryCode=${ countryCode }&screen=${ screen }`
	} );

	return response;
};

export const getPaymentMethods = async function ( { countryCode } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_SRV }pro/paymentMethods?countryCode=${ countryCode }`
	} );

	return response;
};

export const getProPurchaseHistory = async function ( { pageNo = 1, pageSize = 10 } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_SRV }pro/history?pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getProPurchaseDetails = async function ( { id } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_SRV }pro/order-data?orderId=${ id }`
	} );

	return response;
};

export const initOrder = async function ( params ) {
	const response = await XHR.put.bind ( this ) ( {
		url  : `${ configs.USER_SRV }pro/initiate_order`,
		data : {
			vId        : params?.variationId,
			couponCode : params?.coupon,
			recurring  : params?.recurring,
			merchant   : params?.merchant
		}
	} );

	return response;
};

export const createSupportTicket = async function ( { email, orderId, description } ) {
	const response = await XHR.put.bind ( this ) ( {
		url  : `${ configs.USER_SRV }pro/support`,
		data : {
			email,
			orderId,
			description
		}
	} );

	return response;
};

export const reportWalletIssue = async function ( { email, orderId, description } ) {
	const response = await XHR.post.bind ( this ) ( {
		url  : `${ configs.PAYMENTS_BRIDGE }wallet-top-up/order/report`,
		data : {
			email,
			orderId,
			description
		}
	} );

	return response;
};

export const getProCreators = async function ( { pageNo = 1, pageSize = 100, follow = false } ) {
	const response = await XHR.get.bind ( this ) ( {
		url    : `${ configs.USER_API_URL }pro-creators`,
		params : {
			pageNo,
			pageSize,
			follow
		}
	} );

	return response;
};

export const getUserProPlan = async function () {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_SRV }pro/user-plan`
	} );

	return response;
};
